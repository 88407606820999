import React from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

import StyledButton from '../atoms/StyledButton';
import IconRightArrow from '../atoms/IconRightArrow';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '70%',

    '& .MuiTypography-h5, & .MuiTypography-h6': {
      marginBottom: '10px',
    },

    '& .MuiButton-root': {
      marginTop: '15px',
      
      '& .MuiButton-label svg': {
        margin: '0 0 1px 10px',
      },
    },

    '@media screen and (max-width: 959px)': {
      width: '100%',
    },
  },
  openCompany: {
    margin: '50px 0',

    '& .MuiTypography-root, & .MuiButton-label svg path': {
      color: theme.palette.info.main,
      fill: theme.palette.info.main,
    },
  },
  switchAccountant: {
    '& .MuiTypography-root, & .MuiButton-label svg path': {
      color: theme.palette.success.main,
      fill: theme.palette.success.main,
    },
  },
}));

const CreateAccountOptions = ({ handleNextStep }) => {
  const classes = useStyles();

  const renderButtonText = (isOpenCompany) => (
    <>
      {isOpenCompany ? 'Abrir empresa' : 'Trocar de contador'}
      <IconRightArrow />
    </>
  );

  return (
    <Box className={classes.root}>
      <Typography variant="h5" component="h2">Criar conta</Typography>
      <Typography variant="body1" component="h3">
        Mude para a Contabilidade Online e fique livre de processos burocráticos, deixe tudo com a gente!
      </Typography>

      <Box className={classes.openCompany}>
        <Typography variant="h6" component="h4">Estou abrindo empresa</Typography>
        <Typography variant="body1">
          Vamos ajudá-lo a realizar esse sonho, nós cuidaremos de toda burocracia e papelada para você.
        </Typography>
        <StyledButton
          text={renderButtonText(true)}
          onClick={() => handleNextStep('abrir-empresa')}
          variant="outlined2"
          color="secondary"
          width="max-content"
          height="35px"
        />
      </Box>

      <Box className={classes.switchAccountant}>
        <Typography variant="h6" component="h4">Quero trocar de contador</Typography>
        <Typography variant="body1">
          Vamos ajudá-lo a realizar esse sonho, nós cuidaremos de toda burocracia e papelada para você.
        </Typography>
        <StyledButton
          text={renderButtonText()}
          onClick={() => handleNextStep('trocar-contador')}
          variant="outlined2"
          color="success"
          width="max-content"
          height="35px"
        />
      </Box>
    </Box>
  );
};

CreateAccountOptions.propTypes = {
  setProcess: PropTypes.func.isRequired,
};

export default CreateAccountOptions;
