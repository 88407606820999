import React, { useState } from 'react';
import {
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import Visibility from '@material-ui/icons/VisibilityOutlined';
import VisibilityOff from '@material-ui/icons/VisibilityOffOutlined';
import PropTypes from 'prop-types';


const PasswordTextField = ({ label, ...rest }) => {
  const [showPassword, setShowPassword] = useState('');

  const handleMouseDown = (event) => {
    event.preventDefault();
  };
  
  return (

    <FormControl >
      <InputLabel>{label}</InputLabel>
      <Input
        {...rest}
        type={showPassword ? 'text' : 'password'}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="Trocar visibilidade da senha"
              onClick={() => setShowPassword(!showPassword)}
              onMouseDown={handleMouseDown}
              >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        }
      />
    </FormControl>
  );
};

PasswordTextField.defaultProps = {
  label: 'Senha',
  rest: {},
};

PasswordTextField.propTypes = {
  label: PropTypes.string,
  rest: PropTypes.shape(),
};

export default PasswordTextField;
