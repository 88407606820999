import React, { useState } from 'react';
import {
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Button,
  TextField,
  FormHelperText,
  Typography,
  MenuItem,
  Box,
  makeStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import axios from 'axios';

import PasswordTextField from '../atoms/PasswordTextField';
import CheckBoxTermsOfUse from '../molecules/CheckBoxTermsOfUse';
import MaskedTextField from '../molecules/MaskedTextField';
import AutocompleteCityUF from './AutocompleteCityUF';
import { SegmentOptions } from '../functions/Options';
import { CreateAccountOpenCompany } from '../functions/Services';
import {
  EmailValidation,
  LastNameValidation,
  LengthValidation,
  NameValidation,
  ObjectValidation,
  PasswordValidation,
  TermsOfUseValidation,
  PhoneValidation,
} from '../functions/FormValidation';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',

    '& .MuiStepper-root': {
      padding: '20px 0 0',
    },

    '& .MuiFormControl-root': {
      width: '70%',

      '@media screen and (max-width: 959px)': {
        width: '100%',
      },
    },

    '& .MuiFormHelperText-root': {
      color: theme.palette.error.main,
      marginBottom: '20px',
    },
  },
  inputWrap: {
    display: 'grid',
    marginBottom: '30px',
    gap: '15px',
  },
  submitButtons: {
    display: 'flex',
  },
}));

const renderStepLabel = (text) => (
  <StepLabel>
    <Typography variant="body1">{text}</Typography>
  </StepLabel>
);

const OpenCompanyFormDrawer = ({
  data, setData, isLoading, setIsLoading,
}) => {
  const classes = useStyles();
  const [error, setError] = useState('');
  const [activeStep, setActiveStep] = useState(0);
  const name = 'Abrir Empresa | Lead Drawer';

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const validateFields = () => (
    ObjectValidation(data, 8)
    && LengthValidation(data)
    && NameValidation(data)
    && LastNameValidation(data)
    && PhoneValidation(data)
    && EmailValidation(data)
    && PasswordValidation(data)
    // && TermsOfUseValidation(data)
  );

  const isFormValid = () => {
    try {
      return validateFields();
    } catch (e) {
      return false;
    }
  };

  const validateForm = () => {
    try {
      if (validateFields()) {
        setError({});
      }
    } catch (e) {
      setError({ field: e.fileName, message: e.message });
      return false;
    }
    return true;
  };

  const sendDataToHubspot = async () => {
    const portalId = process.env.GATSBY_HUBSPOT_PORTAL_ID;
    const formGuid = process.env.GATSBY_HUBSPOT_ABRIR_EMPRESA_FORM_GUID;
    const url = `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formGuid}`;

    const dataHubspot = {
      fields: [
        {
          name: 'email',
          value: data.email,
        },
        {
          name: 'firstname',
          value: data.name,
        },
        {
          name: 'lastname',
          value: data.lastName,
        },
        {
          name: 'segmento_atuacao',
          value: data.segment,
        },
        {
          name: 'phone',
          value: data.phone,
        },
        {
          name: 'cidade_estado',
          value: `${data.cityName} - ${data.state}`,
        },
      ],
      context: {
        pageUri: window.location.href,
        pageName: document.title,
      },
    };

    try {
      const cloudfare = await axios.get('https://www.cloudflare.com/cdn-cgi/trace');
      if (cloudfare.data) {
        const ipInfo = cloudfare.data.split('\n')[2];
        if (ipInfo.indexOf('ip=') > -1) {
          dataHubspot.context.ipAddress = ipInfo.replace('ip=', '');
        }
      }

      await axios.post(url, dataHubspot);
    } catch (err) {
      setError({ message: 'Desculpe, ocorreu um erro. Tente novamente mais tarde!' });
    }
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      setIsLoading(true);
      await sendDataToHubspot();
      CreateAccountOpenCompany(data, name);
    }
  };

  const changeData = (value, field) => {
    setData({ ...data, [field]: value });
    setError({});
  };

  const handleCityUFChange = (value) => {
    if (value) {
      setData({
        ...data,
        state: value.stateInitial,
        city: value.code,
        cityName: value.name,
      });
    } else {
      setData({
        ...data,
        state: null,
        city: null,
        cityName: null,
      });
    }
    setError({});
  };

  const renderButtons = () => (
    <div>
      <Button
        color="primary"
        onClick={handleBack}
        disabled={activeStep === 0 || isLoading}
      >
        Voltar
      </Button>
      <Button
        color="primary"
        variant="contained"
        onClick={handleNext}
        disabled={isLoading}
      >
        Avançar
      </Button>
    </div>
  );

  return (
    <form
      action={`${process.env.GATSBY_ABRIR_EMPRESA_BASE_URL}/signup`}
      method="post"
      name={name}
      className={classes.root}
    >
      <Typography variant="body1">Abrir empresa na Conube é rápido e online.</Typography>

      <Stepper activeStep={activeStep} orientation="vertical">
        <Step>
          {renderStepLabel('Informações pessoais')}

          <StepContent>
            <Box className={classes.inputWrap}>
              <TextField
                label="Nome"
                name="name"
                value={data.name}
                onChange={(e) => changeData(e.target.value, 'name')}
                error={error.field === 'name'}
                disabled={isLoading}
              />

              <TextField
                label="Sobrenome"
                name="lastName"
                value={data.lastName}
                onChange={(e) => changeData(e.target.value, 'lastName')}
                error={error.field === 'lastName'}
                disabled={isLoading}
              />

              <MaskedTextField
                onChange={(e) => changeData(e.target.value, 'phone')}
                mask="+55 (99) 99999-9999"
                textFieldProps={{
                  label: 'Telefone',
                  name: 'phone',
                  type: 'tel',
                  value: data.phone,
                  floating: true,
                  error: error.field === 'phone',
                }}
                InputProps={{
                  disabled: isLoading,
                }}
              />
            </Box>

            {renderButtons()}
          </StepContent>
        </Step>

        <Step>
          {renderStepLabel('Como será sua nova empresa?')}

          <StepContent>
            <Box className={classes.inputWrap}>
              <TextField
                label="Qual segmento de atuação?"
                name="segmento_atuacao"
                value={data.segment}
                onChange={(e) => changeData(e.target.value, 'segment')}
                disabled={isLoading}
                select
              >
                {SegmentOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>

              <AutocompleteCityUF
                onChange={(value) => handleCityUFChange(value)}
                value={{
                  stateInitial: data.state,
                  code: data.city,
                  name: data.cityName,
                }}
                disabled={isLoading}
              />
            </Box>

            {renderButtons()}
          </StepContent>
        </Step>

        <Step>
          {renderStepLabel('Cadastro')}

          <StepContent>
            <Box className={classes.inputWrap}>
              <TextField
                label="E-mail"
                name="email"
                value={data.email}
                onChange={(e) => changeData(e.target.value, 'email')}
                error={error.field === 'email'}
                disabled={isLoading}
              />

              <PasswordTextField
                label="Senha"
                name="password"
                value={data.password}
                onChange={(e) => changeData(e.target.value, 'password')}
                error={error.field === 'password'}
                disabled={isLoading}
              />

              {/* <CheckBoxTermsOfUse
                checked={data.acceptTermsCheck}
                onChange={(value) => changeData(value, 'acceptTermsCheck')}
                color="primary"
                disabled={isLoading}
              /> */}
            </Box>

            <FormHelperText>{error.message}</FormHelperText>

            <div className={classes.submitButtons}>
              <Button
                color="primary"
                onClick={handleBack}
                disabled={activeStep === 0 || isLoading}
              >
                Voltar
              </Button>

              <Box onClick={validateForm}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  disabled={!isFormValid() || isLoading}
                >
                  Criar conta
                </Button>
              </Box>
            </div>
          </StepContent>
        </Step>
      </Stepper>
    </form>
  );
};

OpenCompanyFormDrawer.propTypes = {
  data: PropTypes.shape().isRequired,
  setData: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default OpenCompanyFormDrawer;
