import React from 'react';
import {
  Checkbox,
  FormControlLabel,
  makeStyles,
} from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
  root: {
    '& svg': {
      width: '20px',
      height: '20px',
    },
  },
});


const StyledCheckBox = ({
  label, checked, onChange, ...rest
}) => {
  const classes = useStyles();

  return (
    <FormControlLabel
      className={classes.root}
      label={label}
      control={(
        <Checkbox
          icon={<CheckBoxOutlineBlankIcon fontSize="large" />}
          checkedIcon={<CheckBoxIcon fontSize="large" />}
          checked={checked}
          onChange={(event) => onChange(event.target.checked)}
          {...rest}
        />
      )}
    />
  );
};

StyledCheckBox.defaultProps = {
  label: '',
};

StyledCheckBox.propTypes = {
  label: PropTypes.any,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default StyledCheckBox;
