import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

import SEO from '../atoms/SEO';
import Header from '../organisms/Header';
import Footer from '../molecules/Footer';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
});

const Main = ({ page, children, purpleFriday }) => {
  const classes = useStyles();
  return (
    <>
      <SEO page={page} />
      {purpleFriday ? (
        <></>
      ) : (
        <Header />
      )}
      <Box className={classes.root}>
        {children}
      </Box>
      {purpleFriday ? (
        <></>
      ) : (
        <Footer />
      )}
    </>
  );
};

Main.defaultProps = {
  children: <></>,
  purpleFriday: false,
};

Main.propTypes = {
  page: PropTypes.string.isRequired,
  children: PropTypes.element,
  purpleFriday: PropTypes.bool,
};

export default Main;
