import React, { useEffect, useState } from 'react';
import { IconButton, Box, Typography, makeStyles } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import PropTypes from 'prop-types';

import StyledDrawer from '../atoms/StyledDrawer';
import CenteredLoading from '../atoms/CenteredLoading';
import IconLeftArrow from '../atoms/IconLeftArrow';
import CreateAccountOptions from '../molecules/CreateAccountOptions';
import OpenCompanyFormDrawer from './OpenCompanyFormDrawer';
import SwitchAccountantFormDrawer from './SwitchAccountantFormDrawer';

const useStyles = makeStyles({
  root: {
    '& .MuiDrawer-paper': {
      width: '550px',
      margin: '10px',
      height: '89%',
      borderRadius: '25px !important',

      '@media screen and (max-height: 800px)': {
        height: '87%',
      },

      '@media screen and (max-width: 959px)': {
        width: '90%',
        height: '100vh',
        margin: 0,
        borderRadius: '0 !important',

        '& .react-swipeable-view-container': {
          paddingBottom: '200px',
        },
      },

      '@media screen and (max-width: 500px)': {
        width: '88%',
      },
      '@media screen and (max-width: 430px)': {
        width: '86%',
      },
      '@media screen and (max-width: 360px)': {
        width: '84%',
      },
    },

    '& .loaded': {
      opacity: '1',
      transition: '0.2s',
    },

    '& .loading': {
      opacity: '0.5',
      transition: '0.2s',
    },
  },
  top: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    marginBottom: '15px',

    '@media screen and (max-width: 430px)': {
      alignItems: 'flex-start',
    },
  },
});

const CreateAccountDrawer = ({
  open,
  setOpen,
  process,
  step,
  setStep,
  openCompanyData,
  setOpenCompanyData,
  switchAccountantData,
  setSwitchAccountantData,
  goBack,
}) => {
  const classes = useStyles();
  const [form, setForm] = useState('abrir-empresa');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (process !== 'default') {
      setForm(process);
    }
  }, [process]);

  const handleNextStep = (chosenProcess) => {
    setForm(chosenProcess);
    setStep(1)
  };

  const handleReturnStep = () => {
    if (process === 'default') {
      setStep(0);
    } else {
      setOpen(false);
    }
  };

  const handleCloseDrawer = () => {
    setOpen(false);
    setStep(0);
    setForm('abrir-empresa');
    setOpenCompanyData({});
    setSwitchAccountantData({});
    goBack();
  };

  return (
    <StyledDrawer open={open} setOpen={handleCloseDrawer} className={classes.root} disabled={isLoading}>
      <SwipeableViews index={step} onChangeIndex={setStep}>
        <div>
          <CreateAccountOptions handleNextStep={handleNextStep} />
        </div>
        <div className={isLoading ? 'loading' : 'loaded'}>
          <Box className={classes.top}>
            <IconButton color="primary" onClick={handleReturnStep} disabled={isLoading}>
              <IconLeftArrow />
            </IconButton>
            <Typography variant="h5">Certo, vamos iniciar o cadastro</Typography>
          </Box>

          {form === 'abrir-empresa' && (
            <OpenCompanyFormDrawer
              data={openCompanyData}
              setData={setOpenCompanyData}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
            />
          )}

          {form === 'trocar-contador' && (
            <SwitchAccountantFormDrawer
              data={switchAccountantData}
              setData={setSwitchAccountantData}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
            />
          )}
        </div>
      </SwipeableViews>
      {isLoading && <CenteredLoading />}
    </StyledDrawer>
  );
};

CreateAccountDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  process: PropTypes.string.isRequired,
  step: PropTypes.number.isRequired,
  setStep: PropTypes.func,
  openCompanyData: PropTypes.shape(),
  setOpenCompanyData: PropTypes.func,
  switchAccountantData: PropTypes.shape(),
  setSwitchAccountantData: PropTypes.func,
  goBack: PropTypes.func,
};

CreateAccountDrawer.defaultProps = {
  setStep: () => {},
  openCompanyData: {},
  setOpenCompanyData: () => {},
  switchAccountantData: {},
  setSwitchAccountantData: () => {},
  goBack: () => {},
};

export default CreateAccountDrawer;
